import React from "react";
import PageTitle from "../elements/Pagetitle";
import Timeline from "../elements/Timeline";

const educationData = [
  {
    id: 1,
    title: "Master of Science in Electronic Engineering",
    years: "Sep 2012 - Jun 2014",
    contents: [
      "The Hong Kong University of Science and Technology",
    ],
  },
  {
    id: 2,
    title: "Student Exchange Program in Philosophy and Social Sciences",
    years: "Jun 2011 - Jan 2012",
    contents:[
      "National University of Singapore",
    ],
  },
  {
    id: 3,
    title: "Bachelor of Science in Physics and Mathematics",
    years: "Sep 2009 - Jun 2012",
    contents:[
      "The Hong Kong University of Science and Technology,",
      "Magna Cum Laude",
    ]
  },
  {
    id: 4,
    title: "High School",
    years: "Sep 2002 - Jun 2009",
    contents:[
      "Saint Louis School",
    ]
  },
];

const experienceData = [
  {
    id: 1,
    title: "Data Scientist & Cloud Architect",
    years: "Nov 2022 - Present",
    contents:[
      "Digital Asset Team,",
      "Animoca Brands",
    ],
  },
  {
    id: 2,
    title: "Solutions Architect - AI & Data Specialist",
    years: "Jun 2020 - Nov 2022",
    contents:[
      "Presales and Solution Architecture Team,",
      "Microfusion Technology Company Limited",
    ],
  },
  {
    id: 3,
    title: "Head of Client Data Hub (ETL)",
    years: "Nov 2019 - May 2020",
    contents:[
      "Client Data Hub (ETL) Team,",
      "Privé Technologies",
    ],
  },
  {
    id: 4,
    title: "Robotics Engineer",
    years: "Jan 2018 - Oct 2018",
    contents:[
      "Research and Development Team,",
      "AI Tech Limited"
    ],
  },
];

const Experiences = () => {
  return (
    <section id="experience">
      <div className="container">
        <PageTitle title="Experience" />
        <div className="row">
          <div className="col-md-6">
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
            <div className="timeline exp bg-white rounded shadow-dark padding-30 overflow-hidden">
              {experienceData.map((experience) => (
                  <Timeline key={experience.id} item={experience} />
              ))}
              <span className="line"></span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="timeline edu bg-white rounded shadow-dark padding-30 overflow-hidden">
              {educationData.map((education) => (
                <Timeline key={education.id} item={education} />
              ))}
              <span className="line"></span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Experiences;
