import React from "react";
import ScrollAnimation from "react-animate-on-scroll";

const Timeline = ({ item }) => {
  return (
    <ScrollAnimation
      animateIn="fadeInUp"
      animateOut="fadeInOut"
      animateOnce={true}
    >
      <div className="timeline-container">
        <div className="content">
          <span className="time"><b>{item.years}</b></span>
          <h3 className="title">{item.title}</h3>
          {item.contents.map((content) => (
              <p> {content} </p>
          ))}
        </div>
      </div>
    </ScrollAnimation>
  );
}

export default Timeline;
