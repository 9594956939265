import React, {useContext} from "react";
import Dropdown from "react-dropdown";
import 'react-dropdown/style.css';

const Theme = () => {

    const options = [
        { value: '/', label: 'Mix' },
        { value: '/light', label: 'Light' },
        { value: '/dark', label: 'Dark' },
    ]

    const handleThemeChange = (selectedOption) => {

        if (window.location.pathname !== selectedOption.value) {
            window.location.href = selectedOption.value;
        }
    }
    return (
        <>
            <Dropdown options={options} onChange={handleThemeChange} placeholder="Select a theme" />
        </>
    );
}

export default Theme;