import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import PageTitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "William",
  avatarImage: "/images/avatar.png",
  content:
    "This is William YIP, a GCP certified solutions architect and data scientist with project experience including NLP, image recognition, social media analytics, on-blockchain, off-blockchain data analysis. I am also a MERN stack software engineer who develops smart contracts, builds DeFi and other interesting web applications."
};

const progressData = [
  {
    id: 1,
    title: "Data Science Projects",
    percentage: 90,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Cloud Architecture",
    percentage: 95,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Web3.0 Development",
    percentage: 85,
    progressColor: "#6C6CE5",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <PageTitle title="About Me" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p style={{textAlign:'justify'}}>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a href="https://docs.google.com/spreadsheets/d/1xVQrthTqVdxuuhSqX1Tc5GvGwgwwOaDsk72HGXHPdJQ/" className="btn btn-default" rel="noreferrer" target="_blank">
                      Download CV
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="spacer" data-height="70"></div>*/}
        {/*<div className="row fix-spacing">*/}
        {/*  {counterData.map((counter) => (*/}
        {/*    <div key={counter.id} className="col-md-3 col-sm-6">*/}
        {/*      <TrackVisibility once>*/}
        {/*        <Counter counterItem={counter} />*/}
        {/*      </TrackVisibility>*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
      </div>
    </section>
  );
}

export default About;
