import React, {useContext, useState} from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header from "../components/layouts/Header";
import About from "../components/sections/About";
import Blogs from "../components/sections/Blogs";
import BrandlogosDark from "../components/sections/BrandlogosDark";
import Contact from "../components/sections/Contact";
import Experiences from "../components/sections/Experiences";
import Herosection from "../components/sections/Herosection";
import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import Testimonials from "../components/sections/Testimonials";
import Works from "../components/sections/Works";
import {PinContext} from "../components/contexts/PinContext";
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import ScrollToTop from "react-scroll-to-top";

function HomepageDark() {
  const { pin, rightPin } = useContext(PinContext);
  document.body.classList.add("dark");
  const [toggleMenu, setToggleMenu] = useState(false);
  const headerToggle = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (

    <>
      <Header
        logoSource="/images/logo-wy1.png"
        toggleMenu={toggleMenu}
        headerToggle={headerToggle}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection />
          </ReactCursorPosition>
        </Element>
        {
          pin === rightPin ?
              <div>
                <Element name="section-about">
                  <About />
                </Element>
                <Element name="section-experiences">
                  <Experiences />
                </Element>
                <Element name="section-works">
                  <Works />
                </Element>
                <Element name="section-contact">
                  <Contact />
                </Element>
                <div className="spacer" data-height="96"></div>
              </div> :
              <div/>
        }
        <ScrollToTop smooth/>
      </main>
    </>
  );
}

export default HomepageDark;
