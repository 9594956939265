import React, {useState} from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import HomepageLight from "./pages/HomepageLight";
import HomepageDark from "./pages/HomepageDark";
import Homepage2 from "./pages/Homepage2";
import Homepage2Light from "./pages/Homepage2Light";
import Homepage2Dark from "./pages/Homepage2Dark";
import Homepage3 from "./pages/Homepage3";
import Homepage3Light from "./pages/Homepage3Light";
import Homepage3Dark from "./pages/Homepage3Dark";
import Bloglist from "./pages/Bloglist";
import BlogDetails from "./pages/BlogDetails";
import "./App.scss";
import { PinContext } from "./components/contexts/PinContext";
import moment from 'moment-timezone';

function App() {
  // const ct =  ("0" + String(new Date().getHours())).slice(-2) + ("0" + String(new Date().getMinutes())).slice(-2);

  const currentTime = moment.utc();
  const userTimezone = moment.tz.guess(); // Get user's timezone
  const userNow = currentTime.tz(userTimezone);
  const ct = userNow.format('HHmm');

  const [pin, setPin] = useState("");
  const [rightPin, setRightPin] = useState(ct);

  return (
      <div className="all">
        <PinContext.Provider value={{pin, setPin, rightPin, setRightPin}}>
          <BrowserRouter>
            <Switch>
              <Route path="/" exact>
                <Homepage/>
              </Route>
              <Route path="/dark" exact>
                <HomepageDark/>
              </Route>
              <Route path="/light" exact>
                <HomepageLight/>
              </Route>
              {/*<Route path="/index-2" exact>*/}
              {/*  <Homepage2 />*/}
              {/*</Route>*/}
              {/*<Route path="/index-2-light" exact>*/}
              {/*  <Homepage2Light />*/}
              {/*</Route>*/}
              {/*<Route path="/index-2-dark" exact>*/}
              {/*  <Homepage2Dark />*/}
              {/*</Route>*/}
              {/*<Route path="/index-3" exact>*/}
              {/*  <Homepage3 />*/}
              {/*</Route>*/}
              {/*<Route path="/index-3-light" exact>*/}
              {/*  <Homepage3Light />*/}
              {/*</Route>*/}
              {/*<Route path="/index-3-dark" exact>*/}
              {/*  <Homepage3Dark />*/}
              {/*</Route>*/}
              {/*<Route path="/blogs" component={Bloglist} exact />*/}
              {/*<Route path="/blogs/blog-details/:id/:title" component={BlogDetails} />*/}
            </Switch>
          </BrowserRouter>
        </PinContext.Provider>
      </div>
  );
}

export default App;
