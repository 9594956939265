import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import PageTitle from "../elements/Pagetitle";
import { GoogleSpreadsheet } from 'google-spreadsheet';
import env from "react-dotenv";

function Contact() {

  const update_google_sheet = async (time,name,email,subject,message) => {
    const g_sheet = new GoogleSpreadsheet('1eRSqp9d2n5awG4bsKMrQ0SlGEWJuC2JQqnuXnkrYce8');
    await g_sheet.useServiceAccountAuth({
      client_email: env.GOOGLE_SERVICE_ACCOUNT_EMAIL,
      private_key: env.GOOGLE_PRIVATE_KEY,
    });

    await g_sheet.loadInfo(); // loads document properties and worksheets

    const sheet = g_sheet.sheetsByTitle["msg"]; // or use doc.sheetsById[id] or doc.sheetsByTitle[title]
    await sheet.loadCells({ // GridRange object
      startColumnIndex:0, endColumnIndex: 1
    });
    const new_row_a1 = sheet.cellStats.nonEmpty + 1;

    await sheet.loadCells(`A${new_row_a1}:E${new_row_a1}`);
    const time_cell = sheet.getCellByA1(`A${new_row_a1}`);
    const name_cell = sheet.getCellByA1(`B${new_row_a1}`);
    const email_cell = sheet.getCellByA1(`C${new_row_a1}`);
    const subject_cell = sheet.getCellByA1(`D${new_row_a1}`);
    const message_cell = sheet.getCellByA1(`E${new_row_a1}`);

    time_cell.value = time;
    name_cell.value = name;
    email_cell.value = email;
    subject_cell.value = subject;
    message_cell.value = message;

    await sheet.saveUpdatedCells();
  };


  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(true);
  const [message, setMessage] = useState("");
  const [buttonEnabled, setButtonEnabled] = useState(true);

  const submitHandler = async (event) => {
    event.preventDefault();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    if (!formdata.name || !emailRegex.test(formdata.email) || !formdata.subject || !formdata.message) {
      setError(true);
      const err_items = [];
      !formdata.name && err_items.push("Name");
      !emailRegex.test(formdata.email) && err_items.push("Email");
      !formdata.subject && err_items.push("Subject");
      !formdata.message && err_items.push("Message");
      setMessage(`Invalid ${err_items.join(", ")} :(`);
    } else {
      setError(false);
      setMessage("");
      setButtonEnabled(false);
      const dt = new Date(Date.now())
      await update_google_sheet(dt.toString(),formdata.name,formdata.email,formdata.subject,formdata.message);
      setMessage("Your message has been sent :)");
      setButtonEnabled(true);
    }
  };

  const handleChange =  (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <PageTitle title="Get in Touch" />

        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>Let's talk about everything!</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                {/*<p>*/}
                {/*  Drop me an{" "}*/}
                {/*  <a href="mailto:william.yip@mensa.org.hk">email</a>. 👋*/}
                {/*</p>*/}
                <p>
                  Drop me a message👋
                </p>
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="InputName"
                      placeholder="Your name"
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="email"
                      placeholder="Email address"
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputSubject"
                      name="subject"
                      placeholder="Subject"
                      onChange={handleChange}
                      value={formdata.subject}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      placeholder="Message"
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              {
                buttonEnabled ?
                    <button
                        type="submit"
                        name="submit"
                        id="submit"
                        value="Submit"
                        className="btn btn-default"
                    >
                      Send
                    </button> :
                    <button
                        className="btn btn-disabled"
                    >
                      Sending...
                    </button>
              }
            </form>
            {handleAlerts()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
