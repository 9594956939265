import React,{ useContext } from "react";
import { Link } from "react-scroll";
import Logo from "../elements/Logo";
import { PinContext } from "../contexts/PinContext";
import Theme from "./Theme";

function Header({ light, logoSource, toggleMenu, headerToggle }) {
  const { pin, rightPin } = useContext(PinContext);
  // const right = ("0" + String(new Date().getMonth()+1)).slice(-2) + String(new Date().getDate());
  const handleClasses = () => {
    let classes = "desktop-header-1 d-flex align-items-start flex-column";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  const handleMobileClasses = () => {
    let classes = "mobile-header-1";
    if (light & toggleMenu) {
      classes += " light open";
    } else if (toggleMenu) {
      classes += " open";
    } else if (light) {
      classes += " light";
    }
    return classes;
  };
  return (
    <>
      <header className={handleMobileClasses()}>
        <div className="container">
          <div className="menu-icon d-inline-flex mr-4">
            <button onClick={headerToggle}>
              <span></span>
            </button>
          </div>
          <Logo logoSource={logoSource} />
        </div>
      </header>
      <header className={handleClasses()}>
        <Logo logoSource={logoSource} />
        <nav>
          <ul className="vertical-menu scrollspy">
            <li>
              <Link
                activeClass="active"
                to="section-home"
                spy={true}
                smooth={true}
                duration={500}
              >
                <i className="icon-home"></i>Home
              </Link>
            </li>
            {
              pin === rightPin ?
                  <div>
                    <li>
                      <Link
                          activeClass="active"
                          to="section-about"
                          spy={true}
                          smooth={true}
                          duration={500}
                      >
                        <i className="icon-user-following"></i>About Me
                      </Link>
                    </li>
                    <li>
                      <Link
                          activeClass="active"
                          to="section-experiences"
                          spy={true}
                          smooth={true}
                          duration={500}
                      >
                        <i className="icon-graduation"></i>Experience
                      </Link>
                    </li>
                    <li>
                      <Link
                          activeClass="active"
                          to="section-works"
                          spy={true}
                          smooth={true}
                          duration={500}
                      >
                        <i className="icon-layers"></i>Recent Works
                      </Link>
                    </li>
                    <li>
                      <Link
                          activeClass="active"
                          to="section-contact"
                          spy={true}
                          smooth={true}
                          duration={500}
                      >
                        <i className="icon-bubbles"></i>Contact
                      </Link>
                    </li>
                  </div> :
                  <div/>
            }
            <li>
              <Theme/>
            </li>
          </ul>
        </nav>

        <div className="footer">
          <span className="copyright">
            &copy; {new Date().getFullYear()} Plus Ultra.
            {/*&copy;  Plus Ultra. {rightPin}*/}
          </span>
        </div>
      </header>
    </>
  );
}

export default Header;
